import React from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts';

const RenderLineChart = ({totalOrganization, activeOrganization, inActiveOrganization}) => {
    const data = [
        { name: 'Total Organization', uv: totalOrganization, pv: totalOrganization, amt: totalOrganization },
        { name: 'Active Organization', uv: activeOrganization, pv: activeOrganization, amt: totalOrganization },
        { name: 'Inactive Organization', uv: inActiveOrganization, pv: activeOrganization, amt: totalOrganization },
    ];

    console.log()

    return (
        <>
            {totalOrganization && (
                <LineChart width={1000} height={400} data={data}>
                <Line type="monotone" dataKey="uv" stroke="blue" name="Active Organizations" />
                <Line type="monotone" dataKey="pv" stroke="green" name="Inactive Organizations" />
                <CartesianGrid stroke="#ccc" />
                <XAxis dataKey="name" />
                <YAxis />
                {/* <Tooltip /> */}
                <Legend />
            </LineChart>
            )}
        </>
    );
};

export default RenderLineChart;
