import * as React from "react";
import "./PrestoredMessages.scss";
import checklist from "../../assets/checklist.png";
import add from "../../assets/add.png";

//components
import {
  PageContainer,
  Button,
  MenuHeader,
  Divider,
  Pagination,
  Input,
} from "../../components/atomics";
import {
  GroupButtons,
  ButtonForGroup,
} from "../../components/atomics/GroupButtons";
import {
  PredefinedMessagesItem,
  NewTemplate,
} from "../../components/PredefinedMessages";
import { CSpinner } from "@coreui/react";

//services
import { TemplateMessageResponse } from "../../services/types";
import {
  getAllCustomTemplatesForPagination,
  getAllStandardTemplatesForPagination,
} from "../../services/messagesService";
import HandleUIError from "../../utils/HandleUIError";
import { title } from "process";

interface SwitchTemplatesProps {
  custom: boolean;
  standard: boolean;
}

const PredefinedMessages: React.FC = () => {
  const [switchTemplate, setSwitchTemplate] =
    React.useState<SwitchTemplatesProps>({
      custom: true,
      standard: false,
    });
  const [visibleNewTemplate, setVisibleNewTemplate] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [templates, setTemplates] = React.useState<TemplateMessageResponse[]>();
  const [templatesPageSize] = React.useState<number>(10);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [numberOfPages, setNumberOfPages] = React.useState(0);
  const [order, setOrder] = React.useState("DESC");

  const [searchedData, setSearchedData] = React.useState("")
  const [results, setResults] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [type, setType] = React.useState("");

  function openVisibleNewTemplate() {
    setVisibleNewTemplate(true);
  }

  function changeToCustom() {
    setSwitchTemplate({
      custom: true,
      standard: false,
    });
  }


  React.useEffect(
    function () {
      getAllTemplates();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [switchTemplate.custom, currentPage, type]
  );

  React.useEffect(
    function () {
      setCurrentPage(1);
    },
    [switchTemplate.custom]
  );

  function changeToStandard() {
    setSwitchTemplate({
      custom: false,
      standard: true,
    });
  }

  async function getAllTemplates() {
    setIsLoading(true);
    try {
      let templatesResponse: {
        data: TemplateMessageResponse[];
        numberOfTotalPages: number;
      };
      if (switchTemplate.custom) {
        templatesResponse = await getAllCustomTemplatesForPagination(
          templatesPageSize,
          currentPage,
          order,
          searchedData,
          type
        );
      } else {
        templatesResponse = await getAllStandardTemplatesForPagination(
          templatesPageSize,
          currentPage,
          order,
          searchedData,
          type
        );
      }
      setTemplates(templatesResponse.data);
      setNumberOfPages(
        templatesResponse.numberOfTotalPages / templatesPageSize
      );
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      HandleUIError(e);
    }
  }

  function closeVisibleNewTemplate() {
    setVisibleNewTemplate(false);
  }



  const handleInputChange = (event: any) => {
    setSearchedData(event.target.value);
  };

  const search = async (searchedData: any) => {
    if (switchTemplate.custom) {

      try {
        const res = await getAllCustomTemplatesForPagination(
          templatesPageSize,
          currentPage,
          order,
          searchedData,
          type);
        // setLoading(false)
        console.log("data", res?.data);
        setTemplates(res.data);
      } catch (e) {
        setLoading(false);
        HandleUIError(e);
      }
    } else {
      try {
        const res = await getAllStandardTemplatesForPagination(
          templatesPageSize,
          currentPage,
          order,
          searchedData,
          type);
        // setLoading(false)
        console.log("data", res?.data);
        setTemplates(res.data);
      } catch (e) {
        setLoading(false);
        HandleUIError(e);
      }
    }
  }


  React.useEffect(() => {
    search(searchedData)

    // Fetch data only if query is not empty
    if (searchedData.trim()?.length > 2) {
      search(searchedData);
    } else {
      // Clear results if query is empty
      setResults([]);
    }
  }, [searchedData, type]
  );

  React.useEffect(() => {
    orderSorting(order)
  }, [order, type])

  const handleChangeOrder = (e: any) => {
    setOrder(e.target.value)
  }

  const orderSorting = async (order: any) => {
    if (switchTemplate.custom) {

      try {
        const res = await getAllCustomTemplatesForPagination(
          templatesPageSize,
          currentPage,
          order,
          searchedData,
          type
        );
        // setLoading(false)
        console.log("data", res?.data);
        setTemplates(res.data);
      } catch (e) {
        setLoading(false);
        HandleUIError(e);
      }
    } else
      try {
        const res = await getAllStandardTemplatesForPagination(
          templatesPageSize,
          currentPage,
          order,
          searchedData,
          type
        );
        // setLoading(false)
        console.log("data", res?.data);
        setTemplates(res.data);
      } catch (e) {
        setLoading(false);
        HandleUIError(e);
      }
  }


  return (
    <div className="bg-main h-100 overflow-auto pb-3">
      <div className="">
        <PageContainer className="pre-stored-messages-container d-flex flex-column p-0">
          <div className="d-flex px-4 justify-content-between align-items-center messages-header-container">
            <p className="text-light fs-5">Pre-Defined Messages</p>
            {switchTemplate.custom && (
              <Button
                className="buttons-messages-screen"
                onClick={openVisibleNewTemplate}
              >
                New
              </Button>
            )}
          </div>
          <div>

            <div className="row">
              <div className="col">


                <GroupButtons className="mt-4 ms-4">
                  <ButtonForGroup
                    //@ts-ignore
                    label={
                      <>
                        <img src={add} width={25} height={25} alt="" />
                        Custom

                      </>
                    }
                    onClick={changeToCustom}
                    checked={switchTemplate.custom}
                  />
                  <ButtonForGroup
                    //@ts-ignore
                    label={
                      <>
                        <img src={checklist} width={25} height={25} alt="" />
                        Standard

                      </>
                    }
                    checked={switchTemplate.standard}
                    onClick={changeToStandard}
                  />
                </GroupButtons>


              </div>

              <div className="col-lg-3 mt-4">
                <div className="form-group">
                  {/* <label for="exampleFormControlSelect1">Example select</label> */}
                  <select
                    onChange={(e: any) => setType(e.target.value)}
                    value={type}
                    className="form-select input-select-custom"
                    id="exampleFormControlSelect1"
                  >
                    <option value="">All</option>
                    <option value="audio">
                      Voice
                    </option>
                    <option value="html">
                      Email
                    </option>
                    <option value="text">
                      Message
                    </option>
                  </select>
                </div>
              </div>

              <div className="col-lg-3 mt-4">
                <div className="form-group">
                  {/* <label for="exampleFormControlSelect1">Example select</label> */}
                  <select
                    onChange={handleChangeOrder}
                    value={order}
                    className="form-select input-select-custom"
                    id="exampleFormControlSelect1"
                  >
                    <option value={"DESC"} selected={order === "DESC"}>Descending Order</option>
                    <option value={"ASC"} selected={order === "ASC"}>Ascending Order</option>
                    <option value={"Alpha"} selected={order === "Alpha"}>Alphabatic Order</option>
                  </select>
                </div>
              </div>

              <div className="col-lg-3 mt-4 me-4">
                <Input
                  type="search"
                  className="w-100 small pe-2"
                  placeholder="Search By Name"
                  onChange={handleInputChange}
                />
                {/* <input type="text" onChange={handleInputChange} className="py-2 form-control bg-transparent" placeholder="Search" /> */}
              </div>
            </div>


            <MenuHeader className="d-flex py-3 mx-4 mt-4">
              <p className="text-color ps-2 w-75 me-5">Messages</p>
              <p className="text-color w-50 me-5">Type</p>
              <p className="text-color ps-2 w-75">Status</p>
            </MenuHeader>
            {!isLoading ? (
              templates?.map((template, index) => {
                return (
                  <>
                    <PredefinedMessagesItem
                      key={index}
                      name={template.name}
                      id={template.id}
                      refreshTemplates={getAllTemplates}
                      isText={template.type === "text"}
                      isEmail={template.type === "html"}
                      isAudio={template.type === "audio"}
                      text={template.text}
                      title={template.title}
                      category={template.category}
                      filePath={template.filePath}
                      status={template.status}
                    />
                    {index !== templates.length - 1 && (
                      <div className="px-4">
                        <Divider />
                      </div>
                    )}
                  </>
                );
              })
            ) : (
              <div className="d-flex mt-5 justify-content-center">
                <CSpinner variant="grow" />
              </div>
            )}
          </div>


          {numberOfPages > 1 && (
            <div className="ms-4 mb-3">
              <Pagination
                numberOfPages={numberOfPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          )}
        </PageContainer>
        <NewTemplate
          refreshTemplates={getAllTemplates}
          visible={visibleNewTemplate}
          closeModal={closeVisibleNewTemplate}
        />
      </div>
    </div>
  );
};

export default PredefinedMessages;
